@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./styles/components/buttons.css');
@import url('./styles/components/text.css');
@import url('./styles/components/input.css');
@import url('./styles/components/modal.css');

@import url('./styles/login/login.css');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  @apply font-light;
}

.html-content a {
  @apply text-brand underline;
}

.html-content h1 {
  @apply text-3xl font-bold;
}

.html-content h2 {
  @apply text-2xl font-bold;
}

.html-content h3 {
  @apply text-xl font-bold;
}

.html-content h4 {
  @apply text-lg font-bold;
}

.html-content p {
  @apply text-base;
}

.html-content h1,
.html-content h2,
.html-content h3,
.html-content h4 {
  @apply mt-4 mb-2;
}

.html-content strong {
  @apply font-bold;
}

.html-content ul {
  @apply list-disc pl-4;
}

.html-content table {
  @apply w-full;
}

.html-content table th {
  @apply bg-gray;
}

.html-content table td {
  @apply pr-4 py-4;
}

/* define var(--primary-color) */
:root {
  --primary-color: #e95e40;
  --primary-color-light: rgba(220, 81, 52, 0.03);
}

a,
button {
  @apply cursor-pointer active:opacity-50 transition-opacity duration-200;
}
