@layer login {
  .login-body-container {
    @apply flex h-screen flex-wrap;
  }

  .login-left-container {
    @apply w-full sm:w-1/2 h-screen flex justify-center items-center hidden sm:flex;
  }

  .login-right-container {
    @apply w-full sm:w-1/2 h-screen flex flex-col justify-center items-center;
  }
}
