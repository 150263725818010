@tailwind components;

@layer components {
  .modal {
    @apply fixed inset-0 flex items-center justify-center bg-black/[0.75];
  }

  .modal-content {
    @apply bg-white p-8 rounded-md md:w-2/3 w-full lg:w-1/2 max-w-[600px];
  }
}
