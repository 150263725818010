@tailwind components;

@layer components {
  .textfield {
    @apply h-[49px] bg-transparent border-[1px] border-gray/[0.5] rounded-md w-full px-4;
  }

  .labelSuccess {
    @apply text-success;
  }

  .labelError {
    @apply text-red;
  }
}
