@tailwind components;

@layer components {
  button.primary-btn,
  a.primary-btn {
    @apply rounded-md h-[60px] bg-brand text-white flex items-center justify-center min-w-fit px-8 hover:bg-opacity-90;
  }

  button.secondary-btn,
  a.secondary-btn {
    @apply rounded-md h-[60px] text-gray bg-white flex items-center justify-center min-w-fit px-8;
  }

  button.primary-btn > p,
  button.primary-btn > span,
  a.primary-btn > p,
  a.primary-btn > span {
    @apply text-base text-white;
  }

  button.danger-btn,
  a.danger-btn {
    @apply rounded-md h-[60px] bg-red text-white flex items-center justify-center min-w-fit px-8;
  }

  .secondary-btn {
    @apply rounded-md h-[60px] border-[1px];
  }
}
