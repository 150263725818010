@tailwind components;

@layer components {
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  span,
  label,
  div,
  li,
  td,
  th {
    @apply text-base text-gray;
  }

  .title {
    @apply text-4xl text-gray;
  }

  .subtitle {
    @apply md:text-xl text-lg text-gray;
  }

  .description {
    @apply text-base text-gray;
  }

  .description-bold {
    @apply text-base text-gray font-bold;
  }

  .light {
    @apply text-base text-lightBrown;
  }

  .label {
    @apply text-base font-medium;
  }

  .span {
    @apply text-base text-gray;
  }

  .span-medium {
    @apply text-base text-gray font-medium;
  }

  .paragraph-light {
    @apply text-sm text-textGray;
  }

  body {
    @apply font-montserrat;
  }

  .cms-content > * a {
    @apply text-brand underline !important;
  }

  .link {
    @apply text-base text-brand;
  }
}
